'use strict';

var formValidation = require('base/components/formValidation');
var createErrorNotification = require('base/components/errorNotification');
var clientSideValidation = require('../components/clientSideValidation');

const setNewPassword = function () {
    $('.set-new-password-form').on('submit', function (evt) {
        evt.preventDefault();
        const $setNewPasswordForm = $(this);
        const saveNewPasswordUrl = $setNewPasswordForm.attr('action');

        $.ajax({
            url: saveNewPasswordUrl,
            type: 'post',
            dataType: 'json',
            data: $setNewPasswordForm.serialize(),
            beforeSend: function () {
                $setNewPasswordForm.spinner().start();
            },
            success: function (data) {
                $setNewPasswordForm.spinner().stop();

                if (!data.success) {
                    formValidation($setNewPasswordForm, data);
                } else {
                    $('.set-new-password-wrapper').addClass('d-none');
                    $('.set-new-password-success').removeClass('d-none');
                }
            },
            error: function () {
                $setNewPasswordForm.spinner().stop();
                $setNewPasswordForm.off('submit').trigger('submit');
            }
        });
    });
};

const orderTrack = function () {
    $('body').on('click', '.trackorder .order-track', function (evt) {
        evt.preventDefault();
        const url = $(this).closest('form').attr('action');
        const $form = $(this).closest('form');
        const defer = $.Deferred(); // eslint-disable-line
        if (!clientSideValidation.customZipAndPhoneValidation($form, null) || $('.trackorder .is-invalid').length) {
            defer.reject();
            return defer;
        }
        $.spinner().start();
        $.ajax({
            url: url,
            method: 'POST',
            data: $form.serialize(),
            success: function (data) {
                if (!data.success) {
                    formValidation($form, data);
                    $('#order-status-form-email-error').text(data.errorMSG).show();
                } else if (data.continueURL) {
                    window.location.href = data.continueURL;
                }
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
};
 // Validate phone number numeric values only
function phoneValidation() {
    $(document).on('keypress keyup blur', '#registration-form-phone', function () {
        var phoneNum = $(this).val();
        $(this).val(phoneNum.replace(/[^\d]/, ''));
    });
}

module.exports = {
    login: function () {
        $('form.login').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            form.spinner().start();
            $('form.login').trigger('login:submit', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    form.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                        $('form.login').trigger('login:error', data);
                    } else {
                        $('form.login').trigger('login:success', data);
                        try {
                            if (data.isEnabled) {
                                window.dataLayer.push(JSON.parse(data.dataLayerObjLogin));
                            }
                        } catch (error) {
                            window.console.error('\nlogin.js: error in {0}', error);
                        }
                        window.location.href = data.redirectUrl;
                    }
                },
                error: function (data) {
                    if (data.responseJSON.redirectUrl) {
                        window.location.href = data.responseJSON.redirectUrl;
                    } else {
                        $('form.login').trigger('login:error', data);
                        form.spinner().stop();
                    }
                }
            });
            return false;
        });
    },

    register: function () {
        $('form.registration').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            form.spinner().start();
            $('form.registration').trigger('login:register', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    form.spinner().stop();
                    if (!data.success) {
                        $('form.registration').trigger('login:register:error', data);
                        formValidation(form, data);
                    } else {
                        $('form.registration').trigger('login:register:success', data);
                        window.location.href = data.redirectUrl;
                    }
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification($('.error-messaging'), err.responseJSON.errorMessage);
                    }

                    form.spinner().stop();
                }
            });
            return false;
        });
    },

    resetPassword: function () {
        $('.reset-password-form').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            form.spinner().start();
            let $resetRecaptcha = form.find('.g-recaptcha');
            let response = grecaptcha.getResponse();// eslint-disable-line
            if (response.length == 0) {
                $resetRecaptcha.addClass('is-invalid');
                form.spinner().stop();
            } else {
                $resetRecaptcha.removeClass('is-invalid');
                $('.reset-password-form').trigger('login:register', e);
                $.ajax({
                    url: url,
                    type: 'post',
                    dataType: 'json',
                    data: form.serialize(),
                    success: function (data) {
                        form.spinner().stop();
                        if (!data.success) {
                            formValidation(form, data);
                        } else {
                            $('.forgot-password-wrapper').addClass('email-sent');
                            $('.request-password-title').text(data.receivedMsgHeading);
                            $('.request-password-body').empty()
                                .append('<p>' + data.receivedMsgBody + '</p>');
                            if (!data.mobile) {
                                $('#submitEmailButton').text(data.buttonText)
                                    .attr('data-dismiss', 'modal');
                            } else {
                                $('.send-email-btn').empty()
                                    .html('<a href="'
                                        + data.returnUrl
                                        + '" class="btn btn-primary btn-block">'
                                        + data.buttonText + '</a>'
                                    );
                            }
                        }
                    },
                    error: function () {
                        form.spinner().stop();
                    }
                });
            }
            return false;
        });
    },

    clearResetForm: function () {
        $('#login .modal').on('hidden.bs.modal', function () {
            $('#reset-password-email').val('');
            $('.modal-dialog .form-control.is-invalid').removeClass('is-invalid');
        });
    },
    setNewPassword: setNewPassword,
    orderTrack: orderTrack,
    phoneValidation: phoneValidation
};
